<template>
   <div class="toggle-menu-button">
      <a @click.prevent.stop="toggle">
         <IconKebab v-if="!isActive" />
         <IconClose v-if="isActive" />
      </a>
   </div>
</template>

<script>
   import IconKebab from '../components/svgs/icons/IconKebab.vue';
   import IconClose from '../components/svgs/icons/IconClose.vue';

   export default {
      name: 'ToggleMenuButton',

      components: {
         IconKebab,
         IconClose,
      },

      data() {
         return {
            isOpen: false
         }
      },

      computed: {
         isActive() {
            return this.isOpen;
         },
      },

      methods: {
         toggle() {
            this.isOpen = !this.isOpen;
            this.$emit('onToggled', this.isOpen);
         },

         reset() {
            this.isOpen = false;
         },
      }
    }
</script>

<style scoped lang="scss">
@import '../styles/_main.scss';

.toggle-menu-button {
   cursor: pointer;
}

</style>