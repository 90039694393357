<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Search</title>
      <g id="Search" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(3.000000, 3.000000)" :fill="fillColor" id="icon/interfaces/search">
              <path d="M13.9994165,13.2923098 L17.8535534,17.1464466 C18.0488155,17.3417088 18.0488155,17.6582912 17.8535534,17.8535534 C17.6582912,18.0488155 17.3417088,18.0488155 17.1464466,17.8535534 L13.2923098,13.9994165 C11.8819612,15.2444908 10.0292099,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,10.0292099 15.2444908,11.8819612 13.9994165,13.2923098 Z M8,15 C11.8659932,15 15,11.8659932 15,8 C15,4.13400675 11.8659932,1 8,1 C4.13400675,1 1,4.13400675 1,8 C1,11.8659932 4.13400675,15 8,15 Z"></path>
          </g>
      </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
  },
  name: 'IconSearch',
};
</script>
