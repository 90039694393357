<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Show</title>
      <g id="Show" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(2.000000, 4.000000)" :fill="fillColor" id="icon/interfaces/eye">
              <path d="M0.0338193455,7.81923607 C2.16808919,2.31506648 8.36026781,-0.416777026 13.8644374,1.71749281 C16.6654288,2.80359152 18.8800819,5.01824467 19.9661807,7.81923607 C20.0112731,7.93552716 20.0112731,8.06447284 19.9661807,8.18076393 C17.8319108,13.6849335 11.6397322,16.416777 6.1355626,14.2825072 C3.3345712,13.1964085 1.11991805,10.9817553 0.0338193455,8.18076393 C-0.0112731152,8.06447284 -0.0112731152,7.93552716 0.0338193455,7.81923607 Z M6.49709046,13.3501459 C11.4256883,15.2612348 16.9628618,12.8680219 18.9616531,8.00004745 C17.9532865,5.54665483 15.9813179,3.6108696 13.5029095,2.64985412 C8.57431174,0.73876518 3.0371382,3.13197809 1.03834692,7.99995255 C2.04671349,10.4533452 4.01868212,12.3891304 6.49709046,13.3501459 Z M10,12 C7.790861,12 6,10.209139 6,8 C6,5.790861 7.790861,4 10,4 C12.209139,4 14,5.790861 14,8 C14,10.209139 12.209139,12 10,12 Z M10,11 C11.6568542,11 13,9.65685425 13,8 C13,6.34314575 11.6568542,5 10,5 C8.34314575,5 7,6.34314575 7,8 C7,9.65685425 8.34314575,11 10,11 Z"></path>
          </g>
      </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
  },
  name: 'IconShow',
};
</script>
