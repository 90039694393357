<template>
<div>
   <div v-if="editMode" class="row table-row edit-row">
   <!-- <div v-if="editMode" class="row align-items-center table-row"> -->
      <div class="col-3">
         <input ref="editRowKey" type="text" class="form-control" v-bind:class="{ error: hasEditError }" id="inputFieldKeyName" placeholder="Key Name"
            :value="currentInputText" @input="constrictInput($event)">
         <p v-if="hasEditError" class="error-text error-message mt-2"><span class="error-icon"><IconError /></span>{{this.errorMessage}}</p>
      </div>
      <div class="col-4">
         <textarea ref="editRowValue" class="form-control input-text-area" id="inputFieldValue" rows="1" placeholder="Text Here" :value="itemData.value"></textarea>
      </div>
      <div class="col-2">
      </div>
      <div class="col-3 text-right float-right">
         <button class="btn btn-secondary btn-stripped" @click.prevent.stop="cancelEdit">CANCEL</button>
         <button class="btn btn-primary" @click.prevent.stop="saveRow">UPDATE</button>
      </div>
   </div>
   <div v-else class="row align-items-center table-row">
      <div class="col-3">{{itemData.key}}</div>
      <div class="col-4"><DisplayTableValue :valueText="itemData.value"/></div>
      <div class="col-3">{{itemData.updatedAtString}}</div>
      <div class="col-2 text-right float-right">
         <ToggleMenuButton ref="toggleMenuButton" class="input-icon" v-on:onToggled="onMenuToggled" />
         <RowMenu v-if="isMenuOpen" v-on:onEdit="editRow" v-on:onDeleteConfirmed="confirmDeletion" />
      </div>
   </div>
</div>
</template>

<script>
   import ToggleMenuButton from '../components/ToggleMenuButton.vue';
   import RowMenu from '../components/RowMenu.vue';
   import DisplayTableValue from '../components/DisplayTableValue.vue';
   import IconError from '../components/svgs/icons/IconError.vue';

   export default {
      name: 'DisplayTableRow',

      components: {
         ToggleMenuButton,
         RowMenu,
         DisplayTableValue,
         IconError,
      },

      props: [
         'itemData',
         'editRowCallback',
      ],

      data() {
         return{
            editMode: false,
            editedItemData: {},
            currentInputText: this.itemData.key,
            isMenuOpen: false,
            hasEditError: true,
            errorMessage: "some error message",
         }
      },

      methods: {
         closeMenu() {
            // force the menu closed, as some other menu was opened
            this.isMenuOpen = false;
            this.$refs.toggleMenuButton.reset();
         },

         constrictInput(e) {
            e.preventDefault();
            this.currentInputText = e.target.value.toLowerCase().replace(" ", "_");
         },

         onMenuToggled(newValue) {
            this.isMenuOpen = newValue;
            if (this.isMenuOpen) {
               // so if there's any other menu opened, we close it
               this.$emit('onMenuOpened', this);
            } else {
               this.$emit('onMenuClosed');
            }
         },

         editRow() {
            this.hasEditError = false;
            this.currentInputText = this.itemData.key;
            this.isMenuOpen = false;
            this.$emit('onMenuClosed');
            this.editMode = true;
         },

         confirmDeletion() {
            this.$emit('onRowDeleted', this.itemData.id);
         },

         cancelEdit() {
            this.hasEditError = false;
            this.editMode = false;
         },

         onEdited() {
            this.hasEditError = false;
            this.editMode = false;
         },

         onEditError(message) {
            this.hasEditError = true;
            this.errorMessage = message;
         },

         saveRow() {
            this.hasEditError = false;
            this.editRowCallback(
               {
                  id: this.itemData.id,
                  key: this.$refs.editRowKey.value,
                  value: this.$refs.editRowValue.value,
                  updated_at: this.itemData.updated_at,
               },
               this.onEdited,
               (message) => {
                  this.onEditError(message);
               }
            );
         },
      },

      watch: {
         itemData: function () {
            // if this row data changes and we were editing, just cancel the edit
            // ie: a search has been performed
            this.editMode = false;
         },
      },
   }
</script>

<style scoped lang="scss">
@import '../styles/_main.scss';

.table-row {
   border-bottom: 2px solid rgba(19, 20, 21,.05);
   color: $color2;
   padding: 16px 0;
}

.edit-button {
   margin-right: 5px;
}

.error-message {
   padding-left: 10px;
   margin-bottom: 0;
}

.error-icon {
   display: inline-block;
   margin-right: 8px;
}
</style>