<template>
   <div class="category-selector">
      <div class="container">
         <div class="btn-group" role="group">
            <CategoryButton v-for="(item, i) in categoriesData" :key="'row' + i"
               :title="item.title"
               :value="item.value"
               :icon="item.icon"
               :changed="changed"
               :isSelected="item.value === currentCategory"
            />
         </div>      
      </div>
   </div>
</template>

<script>
   import CategoryButton from '../components/CategoryButton';
   
   export default {
      name: 'CategorySelector',

      components: {
         CategoryButton,
      },

      props: [
         'changed',
         'categoriesData',
         'currentCategory',
      ],

   }
</script>

<style scoped lang="scss">
@import '../styles/_main.scss';

.category-selector {
   display: block;
   position: relative;
   background-color: $color1;
   width: 100%;
   height: 62px;

   .container {
      height: 100%;

      .btn-group {
         height: 100%;
      }
   }
}
</style>