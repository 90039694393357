<template>
   <div class="toggle-more-button mt-2">
      <a @click.prevent.stop="toggle">
         <div class="d-flex justify-content-between align-items-center button-container">
            <span v-if="!isActive">SHOW MORE</span>
            <span v-else>SHOW LESS</span>
            <IconDown v-if="!isActive" class="button-arrow-icon" />
            <IconUp v-else class="button-arrow-icon" />
         </div>
      </a>
   </div>
</template>

<script>
   import IconUp from '../components/svgs/icons/IconArrowUp.vue';
   import IconDown from '../components/svgs/icons/IconArrowDown.vue';

   export default {
      name: 'ToggleMoreButton',

      components: {
         IconUp,
         IconDown,
      },

      data() {
         return {
            isOpen: false
         }
      },

      computed: {
         isActive() {
            return this.isOpen;
         },
      },

      methods: {
         toggle() {
            this.isOpen = !this.isOpen;
            this.$emit('onToggled', this.isOpen);
         },

         reset() {
            this.isOpen = false;
         },
      }
    }
</script>

<style scoped lang="scss">
@import '../styles/_main.scss';

.toggle-more-button {
   cursor: pointer;
   user-select: none;
   font-size: $smallFontSize;
}

.button-container {
   width: 120px;
}

.button-arrow-icon {
   float: right;
}

</style>