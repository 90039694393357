<template>
   <div class="category-button">
      <button class="button-container"
         v-bind:class="{ selected: isSelected }"
         type="button" @click.prevent="changed(value)">
         <component :is="icon" class="button-icon"/>
         {{title}}
      </button>
   </div>
</template>

<script>
   import IconVR from '../components/svgs/icons/IconVR.vue';
   import IconMobile from '../components/svgs/icons/IconMobile.vue';
   import IconNotifications from '../components/svgs/icons/IconNotifications.vue';

   export default {
      name: 'CategoryButton',

      components: {
         IconVR,
         IconMobile,
         IconNotifications,
      },

      props: [
         'title',
         'value',
         'icon',
         'changed',
         'isSelected',
      ],
   }
</script>

<style scoped lang="scss">
@import '../styles/_main.scss';

.category-button {
   display: inline-block;
   height: 100%;
   width: 120px;
}

.button-container {
   display: block;
   background-color: $color1;
   color: #fff;
   width: 100%;
   height: 100%;
   border: none;
   text-align: center;
   transition: all .2s linear;
   font-size: $smallFontSize !important;
   
   &:hover {
      background-color: $color17 !important;
   }

   &.selected {
      background-color: #fff;
      color: $color1;
      cursor: auto;
      &:hover {
         background-color: #fff !important;
      }
   }

   .button-icon {
      display: block;
      margin-left: 50%;
      transform: translateX(-50%);
      max-height: 24px;
   }
}

</style>