<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Down</title>
      <g id="Down" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M15.1812705,3.1692631 C15.3639348,2.96216804 15.6798972,2.94236299 15.8869923,3.12502729 C16.0710768,3.28739555 16.1071791,3.55508577 15.9846382,3.75801742 L15.9312281,3.83074904 L9.16624933,11.5 L15.9312281,19.1692631 C16.0935964,19.3533476 16.0959889,19.6234507 15.9490665,19.809492 L15.8869923,19.8749849 C15.7029078,20.0373531 15.4328047,20.0397456 15.2467634,19.8928232 L15.1812705,19.830749 L8.12502122,11.830749 C7.97916313,11.6653829 7.96093087,11.4276527 8.07032444,11.2441955 L8.12502122,11.1692631 L15.1812705,3.1692631 Z" id="Path" :fill="fillColor" transform="translate(12.028128, 11.500006) scale(-1, 1) rotate(-90.000000) translate(-12.028128, -11.500006) "></path>
      </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
  },
  name: 'IconArrowDown',
};
</script>
