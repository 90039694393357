<template>
   <div class="container">
      <span class="separator"></span>
      <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-center align-items-center" >
         <a class="nav-link" href="https://koffeecup.net" target="_blank">www.koffeecup.net</a>
         <a class="nav-link" href="mailto:hello@koffeecup.net" target="_blank">hello@koffeecup.net</a>
      </div>
   </div>
</template>

<script>
   export default {
      name: 'Footer',
   }
</script>

<style scoped lang="scss">
@import '../styles/_main.scss';

</style>