const state = {
   userName: '',
};

const getters = {
};

const actions = {
};

const mutations = {

};

export default {
   state,
   getters,
   actions,
   mutations
}