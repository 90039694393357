<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-1" :fill="fillColor" d="M4.74,5.83H19.35a1.82,1.82,0,0,1,1.84,1.46,2,2,0,0,1,0,.49c0,2.88,0,5.76,0,8.65a1.89,1.89,0,0,1-2,1.95H8.13a.63.63,0,0,1-.37-.12c-1-.74-1.93-1.49-2.89-2.24a.34.34,0,0,1-.13-.24q0-4.9,0-9.79S4.74,5.9,4.74,5.83ZM19.16,16.34V7.87H6.8a1,1,0,0,0,0,.16v6.74a.38.38,0,0,0,.13.25c.5.4,1,.8,1.53,1.18a.7.7,0,0,0,.39.13H19.16Z"/><path class="cls-1" :fill="fillColor" d="M3.15,18.91l1.29-1.58,2,1.58L5.19,20.49Z"/></g></g></g></g></svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
  },
  name: 'IconNotifications',
};
</script>
