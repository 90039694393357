import Home from './pages/Home'
import Login from './pages/Login'

export const routes = [
   {
      path: '*',
      redirect: '/'
   },{
      path: '/',
      name: 'Login',
      component: Login
   },{
      path: '/dashboard',
      name: 'Home',
      component: Home,
      meta: {
         requiresAuth: true
      }
   },
];