<template>
   <div class="login-page has-bg container-fluid">
      <div class="position-absolute mt-5 ml-5 maloka-container">
         <MalokaLogo class="maloka-logo" />
         <!-- <h1 class="mt-4 maloka-quote">“we’re all just walking each other home”</h1> -->
      </div>
      <div class="login-panel">
         <form @submit.prevent="login">
            <label for="email-field">USERNAME</label><br>
            <div class="position-relative">
               <input id="email-field" class="form-control" type="email" placeholder="probably an email..." v-model="email" />
               <IconUser class="input-icon" />
            </div>
            <br>
            <label for="password-field">PASSWORD</label><br>
            <div class="position-relative">
               <input id="password-field" class="form-control" placeholder="shhh... your password here" v-model="password"
               :type="passwordFieldType" />
               <ShowPasswordButton class="input-icon" v-on:onToggled="passwordToggle" />
            </div>
            <br>
            <div class="text-right">
               <button class="btn btn-secondary">Sign In</button>
            </div>
            <p class="error-text mt-2">{{this.errorMessage}}</p>
         </form>
         <span class="separator"></span>
         <div class="text-right mt-3">
            <button id="loginButtonGoogle" class="btn btn-secondary" @click.prevent.stop="loginGoogle">SIGN IN WITH GOOGLE</button>
         </div>
      </div>
   </div>
</template>

<script>
   import firebase from 'firebase';
   import MalokaLogo from '../components/svgs/MalokaLogo.vue';
   import IconUser from '../components/svgs/icons/IconUser.vue';
   import ShowPasswordButton from '../components/ShowPasswordButton.vue';

   export default {
      name: 'Login',

      components: {
         MalokaLogo,
         IconUser,
         ShowPasswordButton,
      },

      data(){
         return{
            email: '',
            password: '',
            successMessage: '',
            errorMessage: '',
            passwordFieldType: 'password'
         }
      },

      methods: {
			login(){
				firebase.auth().signInWithEmailAndPassword(this.email, this.password)
					.then(({user}) => {
                  this.$store.state.authStore.userName = user.displayName || "";
						this.$router.replace('dashboard');
					})
					.catch(err => {
                  // err.code values
                  //    auth/invalid-email
                  //    auth/user-not-found
                  //    auth/wrong-password
						this.errorMessage = err.message
					});
         },

			loginGoogle(){
            var provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope("profile");
            provider.addScope("email");
            provider.addScope("https://www.googleapis.com/auth/plus.me");
            firebase.auth().useDeviceLanguage();
            firebase.auth().signInWithPopup(provider).then((result) => {
               this.errorMessage = "";
               // this.$store.state.authStore.userName = result.user.displayName || "";
               this.$router.replace('dashboard');
            }).catch((error) => {
               //error occurred, show error message
               this.errorMessage = error.message;
            }); // Opens a popup window and returns a promise to handle errors.
         },

         passwordToggle(isShown) {
            this.passwordFieldType = (isShown) ? "text" : "password";
         },
      }
   }
</script>

<style scoped lang="scss">
@import '../styles/_main.scss';

.maloka-container {
   color: $color6;
   max-width: 100%;
}

.maloka-logo {
   height: 47px;
   max-width: 100%;
   color: $color6;
}

.maloka-quote {
   text-transform: uppercase;
}

.login-page {
   min-height: 100vh;
   min-width: 100vw;
   display:flex;
}

.login-panel {
   border-radius: $borderRadius1;
   background-color: white;
   box-shadow: $boxShadow1;
   padding: 36px 72px;
   height: auto;
   width: 552px;
   margin: auto;
}

</style>
