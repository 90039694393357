<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon / Close</title>
      <g id="Icon-/-Close" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="icon/interfaces/close" transform="translate(7.000000, 7.000000)" :fill="fillColor">
              <path d="M5,4.29289322 L9.14644661,0.146446609 C9.34170876,-0.0488155365 9.65829124,-0.0488155365 9.85355339,0.146446609 C10.0488155,0.341708755 10.0488155,0.658291245 9.85355339,0.853553391 L5.70710678,5 L9.85355339,9.14644661 C10.0488155,9.34170876 10.0488155,9.65829124 9.85355339,9.85355339 C9.65829124,10.0488155 9.34170876,10.0488155 9.14644661,9.85355339 L5,5.70710678 L0.853553391,9.85355339 C0.658291245,10.0488155 0.341708755,10.0488155 0.146446609,9.85355339 C-0.0488155365,9.65829124 -0.0488155365,9.34170876 0.146446609,9.14644661 L4.29289322,5 L0.146446609,0.853553391 C-0.0488155365,0.658291245 -0.0488155365,0.341708755 0.146446609,0.146446609 C0.341708755,-0.0488155365 0.658291245,-0.0488155365 0.853553391,0.146446609 L5,4.29289322 Z"></path>
          </g>
      </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
  },
  name: 'IconClose',
};
</script>
