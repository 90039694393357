<template>
  <svg width="16px" height="16px" viewBox="0 0 11 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Group 13</title>
      <g id="App-Store" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Koffeeshop-Home" transform="translate(-154.000000, -812.000000)">
              <g id="Group-13" transform="translate(154.000000, 812.000000)">
                  <circle id="Oval" :fill="fillColor" cx="5.5" cy="6.3" r="5.5"></circle>
                  <path d="M5.6,6.7 C5.6,6.86568542 5.46568542,7 5.3,7 C5.13431458,7 5,6.86568542 5,6.7 L5,4.3 C5,4.13431458 5.13431458,4 5.3,4 C5.46568542,4 5.6,4.13431458 5.6,4.3 L5.6,6.7 Z" id="Path" stroke="#FFFFFF" stroke-width="0.3" fill="#FFFFFF" fill-rule="nonzero"></path>
                  <polygon id="Path" stroke="#FFFFFF" stroke-width="0.3" fill="#FFFFFF" fill-rule="nonzero" points="5 8.2 5.6 8.2 5.6 8.8 5 8.8"></polygon>
              </g>
          </g>
      </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
  },
  name: 'IconError',
};
</script>
