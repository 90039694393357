<template>
   <div class="search-bar">
      <div class="container">
         <div class="row align-items-center">
            <div class="search-wrapper text-center">
               <input ref="newRowKey" type="text" class="form-control search-input" id="inputFieldKeyName" placeholder="Search"
                  :value="currentSearchText" @input="onSearchChanged($event)">
               <IconSearch class="input-icon" />
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import IconSearch from '../components/svgs/icons/IconSearch.vue';
   
   export default {
      name: 'SearchBar',

      components: {
         IconSearch,
      },

      props: [
      ],

      data() {
         return{
            currentSearchText: '',
         }
      },


      methods: {
         onSearchChanged(e) {
            e.preventDefault();
            this.currentSearchText = e.target.value;
            this.$emit('onChanged', this.currentSearchText);
         },
      },
   }
</script>

<style scoped lang="scss">
@import '../styles/_main.scss';

.search-bar {
   display: block;
   position: relative;
   background-color: #fff;
   width: 100%;
   height: 80px;

   .container {
      height: 100%;

      .row {
         margin: 0;
         height: 100%;
      }
   }

   .search-wrapper {
      width: 360px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
   }

   .search-input {
      border-radius: 20px;
      &::placeholder {
         color: $color9;
      }
   }
}
</style>