<template>
   <div class="show-password-button">
      <a @click.prevent.stop="toggle">
         <IconShow v-if="!isActive" />
         <IconHide v-if="isActive" />
      </a>
   </div>
</template>

<script>
   import IconShow from '../components/svgs/icons/IconShow.vue';
   import IconHide from '../components/svgs/icons/IconHide.vue';

   export default {
      name: 'ShowPasswordButton',

      components: {
         IconShow,
         IconHide,
      },

      data() {
         return {
            isShown: false
         }
      },

      computed: {
         isActive() {
            return this.isShown;
         },
      },

      methods: {
         toggle() {
            this.isShown = !this.isShown;
            this.$emit('onToggled', this.isShown);
         },
      }
    }
</script>

<style scoped lang="scss">
@import '../styles/_main.scss';

.show-password-button {
   cursor: pointer;
}

</style>