import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import BootstrapVue from 'bootstrap-vue';
import { routes } from './routes';
import 'bootstrap';
import './styles/_main.scss';
import firebase from "firebase";
import store from './store/store';

let app = '';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueRouter);

// TODO: These are the config options for the dev DB, switch to live one at some point?
const configOptions = {
  apiKey: "AIzaSyDidGh6LL5HXJuq5MFGW4vgnyxLrGUu8s0",
  authDomain: "maloka-e98ea.firebaseapp.com",
  databaseURL: "https://maloka-e98ea-default-rtdb.firebaseio.com",
  projectId: "maloka-e98ea",
  storageBucket: "maloka-e98ea.appspot.com",
  messagingSenderId: "437907922048",
  appId: "1:437907922048:web:79fd081d27afc67b135a65"
};

firebase.initializeApp(configOptions);

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
// router.beforeResolve((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  store.state.authStore.userName = (currentUser)?currentUser.displayName:"";
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) next('/');
  else if (!requiresAuth && currentUser) next('dashboard');
  else next();
});

firebase.auth().onAuthStateChanged(user => {
  if(!app){
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
});
