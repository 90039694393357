<template>
   <div>
      <div class="container">
         <div class="container header">
            <MalokaLogo class="maloka-logo" />
         </div>
         <div class="container-fluid d-flex justify-content-between welcome-banner">
            <p class="welcome-message">Welcome to the Maloka CMS{{this.userNameString}}</p>
            <button class="btn btn-secondary" @click.prevent.stop="logout">Logout</button>
         </div>
      </div>
      <CategorySelector :changed="onCategoryChange" :categoriesData="categories" :currentCategory="currentCategory" />
      <SearchBar v-on:onChanged="onSearchChanged"/>
      <DisplayTable
         ref="displayTable"
         :tableData="currentTableData"
         :addRowCallback="addRow"
         :deleteRowCallback="deleteRow"
         :editRowCallback="updateRow"
         :filterText="currentSearch"
      />
      <Footer />
   </div>
</template>

<script>
   import firebase from 'firebase';
   import { v4 as uuidv4 } from 'uuid';
   import CategorySelector from '../components/CategorySelector';
   import SearchBar from '../components/SearchBar.vue';
   import DisplayTable from '../components/DisplayTable';
   import Footer from '../components/Footer';
   import MalokaLogo from '../components/svgs/MalokaLogo.vue';

   export default {
      name: 'Home',

      components: {
         CategorySelector,
         SearchBar,
         DisplayTable,
         MalokaLogo,
         Footer,
      },

      data() {
         return{
            database: null,
            userNameString: (this.$store.state.authStore.userName)? `, ${this.$store.state.authStore.userName}!` : "!",
            currentTableData: [],
            currentCategory: "companion",
            currentSearch: "",
            categories: [
               {
                  title: "MOBILE",
                  value: "companion",
                  icon: "IconMobile",
               },
               {
                  title: "VR",
                  value: "vr",
                  icon: "IconVR",
               },
               {
                  title: "NOTIFICATIONS",
                  value: "notifications",
                  icon: "IconNotifications",
               },
            ],
         }
      },

      mounted: function() {
         this.$store.state.authStore.userName = firebase.auth().currentUser.displayName;
         this.database = firebase.database();
         this.reloadTableData();
      },

      methods: {
         logout(){
            firebase.auth().signOut().then(() => {
               this.$router.replace('/');
            });
         },

         generateUID() {
            return uuidv4();
         },

         onSearchChanged(newSearch) {
            this.currentSearch = newSearch;
         },

         onCategoryChange(newCategory) {
            this.currentCategory = newCategory;
            this.reloadTableData();
         },
         
         reloadTableData() {
            this.database.ref('/'+this.currentCategory).once('value').then((snapshot) => {
               let dbTableData = snapshot.val();
               this.updateCurrentTable(dbTableData, this.currentCategory);
            });
         },

         isAlreadyUsed(rowData) {
            for (let i in this.currentTableData) {
               if( (this.currentTableData[i].key === rowData.key)
                     &&
                     (this.currentTableData[i].id !== rowData.id)
               ){
                  return true;
               }
            }
            return false;
         },

         revertDateString(dateString) {
            // this is annoying, but as the proper date value was not saved, we have to reconvert from GB string back to date, and it's dirty
            // and Date.parse would not do options
            // "26/02/2021, 13:13:04"
            return new Date(
               dateString.substr(6, 4),
               parseInt(dateString.substr(3, 2)) - 1,
               dateString.substr(0, 2),
               dateString.substr(12, 2),
               dateString.substr(15, 2),
               dateString.substr(18, 2)
            );
         },

         updateCurrentTable(data, type){
            console.debug("[F] updateCurrentTable")
            this.currentTableData = [];

            if(!Array.isArray(data) && typeof data !== 'object'){
               // there is nothing on the table
               return;
            }

            for (let elemName in data) {
               // >> sample row elem data <<
               // id: "uniqueidgeneratedbyus"
               // key: "some_text_key"
               // value: "Some Text"
               // updated_at: "19/01/2021, 09:59:20"
               let elem = data[elemName];

               if(elem.hasOwnProperty("key")){
                  this.currentTableData.push({
                     id: elem.hasOwnProperty("id") ? elem.id : "",
                     key: elem.hasOwnProperty("key") ? elem.key : "",
                     value: elem.hasOwnProperty("value") ? elem.value : "",
                     updatedAtString: elem.hasOwnProperty("updated_at") ? elem.updated_at : "",
                  });
               }
            }

            // sort the data here, by updated date
            this.currentTableData.sort((a, b) => {
               const dateA = this.revertDateString(a.updatedAtString);
               const dateB = this.revertDateString(b.updatedAtString);
               let comparison = 0;
               if (dateA < dateB) {
                  comparison = 1;
               } else if (dateA > dateB) {
                  comparison = -1;
               }
               return comparison;               
            });
         },

         addOrUpdateRowInCurrentTable(newRowData, onUpdatedCallback, onErrorCallback){
            if (this.isAlreadyUsed(newRowData) ){
               onErrorCallback("Key was already used, each row must have a unique key defined.");
               return;
            }

            if (newRowData.key === null || newRowData.key === ""){
               onErrorCallback("Key is empty, each row must have a unique key defined.");
               return ;
            }

            if (newRowData.key) {
               const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
               let updatedAtDate = new Date(Date.now());
               newRowData.updated_at = updatedAtDate.toLocaleDateString("en-GB", options);
               // if this is called with the same "id" as exists, it edits the row
               this.database.ref(this.currentCategory + '/' + newRowData.id).set(newRowData)
                  .then( () => {
                     this.reloadTableData();
                     onUpdatedCallback();
                  }).catch(function(error) {
                     onErrorCallback("Error writing data: ".concat(error));
                  });
            }
         },

         addRow(newRowData) {
            // new row, needs new uid
            newRowData["id"] = this.generateUID();

            this.addOrUpdateRowInCurrentTable(
               newRowData,
               () => {
                  this.$refs.displayTable.onAdded();
               },
               (message) => {
                  this.$refs.displayTable.onAddedError(message);
               },
            );
         },

         // left this one halfways
         updateRow(newRowData, onEditCallback, onErrorCallback) {
            this.addOrUpdateRowInCurrentTable(
               newRowData,
               () => {
                  onEditCallback();
               },
               (message) => {
                  onErrorCallback(message);
               },
            );
         },

         deleteRow(rowId) {
            this.database.ref(this.currentCategory + '/' + rowId).remove()
               .then( () => {
                  this.reloadTableData();
               }).catch(function(error) {
                  console.error("Error deleting data: ", error);
               });
         }
      }
   }
</script>

<style scoped lang="scss">
@import '../styles/_variables.scss';

.header {
   margin: 26px 0;
}

.maloka-logo {
   color: $color1;
   height: 36px;
}

.welcome-banner {
   margin-bottom: 16px;
}

.welcome-message {
   font-family: $fontPlayfairRegular;
   font-size: $bigFontSize;
}

</style>
