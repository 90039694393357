<template>
   <div class="display-table-value" ref="valueContainer">
      {{this.displayValueText}}
      <ToggleMoreButton v-if="this.isLong" v-on:onToggled="onMoreToggled"/>
   </div>
</template>

<script>
   import ToggleMoreButton from '../components/ToggleMoreButton.vue';

   export default {
      name: 'DisplayTableValue',

      components: {
         ToggleMoreButton,
      },

      props: [
         'valueText',
      ],

      data() {
         return{
            fullTextHeight: 0,
            displayValueText: this.valueText,
            isLong: false,
            displayAll: false,
            maxLength: 120,
         }
      },

      mounted () {
         this.recalculateTextHeight();
      },

      methods: {
         cropText() {
            this.displayValueText = this.valueText.substr(0, this.maxLength);
            //re-trim if we are in the middle of a word
            this.displayValueText = this.displayValueText.substr(0, Math.min(this.displayValueText.length, this.displayValueText.lastIndexOf(" "))).concat("...");
         },

         setText() {
            if (this.displayAll) {
               this.displayValueText = this.valueText;
            } else {
               this.isLong = ( this.fullTextHeight >= 100 );
               if (this.isLong) {
                  this.cropText();
               } else {
                  this.displayValueText = this.valueText;
               }
            }
         },

         onMoreToggled(newValue) {
            this.displayAll = newValue;
            this.setText();
         },

         recalculateTextHeight() {
            this.isLong = false;
            this.displayValueText = this.valueText
            this.$nextTick(() => {
               this.fullTextHeight = this.$refs.valueContainer.clientHeight;
               this.setText();
            });
         },
      },

      watch: {
         valueText: function () {
            this.recalculateTextHeight();
         },
      },
   }
</script>

<style scoped lang="scss">
   @import '../styles/_main.scss';

   .display-table-value {
      word-wrap: break-word;
   }

</style>