<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Left</title>
      <g id="Left" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M15.6250212,3.66925703 C15.8076855,3.46216197 16.1236479,3.44235692 16.330743,3.62502122 C16.5148275,3.78738948 16.5509297,4.0550797 16.4283888,4.25801135 L16.3749788,4.33074297 L9.61,11.9999939 L16.3749788,19.669257 C16.537347,19.8533415 16.5397395,20.1234447 16.3928172,20.3094859 L16.330743,20.3749788 C16.1466585,20.537347 15.8765553,20.5397395 15.6905141,20.3928172 L15.6250212,20.330743 L8.56877189,12.330743 C8.4229138,12.1653768 8.40468154,11.9276466 8.51407511,11.7441895 L8.56877189,11.669257 L15.6250212,3.66925703 Z" :fill="fillColor" id="Path-3"></path>
      </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
  },
  name: 'IconArrowLeft',
};
</script>
