<template>
   <div class="display-table container">
      <div id="content-table" class="container">
         <div class="row align-items-center table-row table-header">
            <div class="col-3 header-text">
               KEY
            </div>
            <div class="col-4 header-text">
               VALUE
            </div>
            <div class="col-3 header-text">
               LAST MODIFIED
            </div>
            <div class="col-2 text-right float-right">
               <div>
                  <button class="btn btn-primary" @click.prevent.stop="addNew">ADD NEW</button>
               </div>
            </div>
         </div>

         <div v-if="this.addingNew" class="row table-row table-row-new">
            <div class="col-3">
               <input ref="newRowKey" type="text" class="form-control" id="inputFieldKeyName" placeholder="Key Name"
                  v-bind:class="{ error: hasAddError }"
                  :value="currentInputText" @input="constrictInput($event)">
               <p v-if="hasAddError" class="error-text error-message mt-2"><span class="error-icon"><IconError /></span>{{this.errorMessage}}</p>
            </div>
            <div class="col-4">
               <textarea ref="newRowValue" class="form-control input-text-area" id="inputFieldValue" rows="1" placeholder="Text Here"></textarea>
               <!-- TODO: use something like this for a big text area? -->
               <!-- <div>
                  <b-form-textarea
                     ref="newRowValue"
                     id="textarea"
                     class="input-text-area"
                     v-model="text"
                     placeholder="Enter something..."
                     rows="0"
                     max-rows="10"
                  ></b-form-textarea>
                  <pre class="mt-3 mb-0">{{ text }}</pre>
               </div> -->
            </div>
            <div class="col-2">
            </div>
            <div class="col-3 text-right float-right">
               <button class="btn btn-secondary btn-stripped shadow-none" @click.prevent.stop="cancelNew">CANCEL</button>
               <button class="btn btn-primary" @click.prevent.stop="saveNew">SAVE</button>
            </div>
         </div>

         <DisplayTableRow v-for="(item, i) in currentPageData" :key="'row' + i"
            :itemData="item"
            :editRowCallback="editRowCallback"
            v-on:onRowDeleted="onRowDeleted"
            v-on:onMenuOpened="onRowMenuOpened"
            v-on:onMenuClosed="onRowMenuClosed"
         />
      </div>

      <b-pagination
         class="page-markers mt-5"
         v-model="currentPage"
         :total-rows="totalRows"
         :per-page="rowsPerPage"
         aria-controls="content-table"
         first-number
         last-number
         align="center"
      >
         <template #prev-text="{disabled}">
            <span class="arrow-icon" v-bind:class="{ 'disabled': disabled }"><IconArrowLeft /></span>
         </template>
         <template #next-text="{disabled}">
            <span class="arrow-icon" v-bind:class="{ 'disabled': disabled }"><IconArrowRight /></span>
         </template>
         <template #page="{ page, active }">
            <span v-if="active">{{ page }}</span>
            <span v-else>{{ page }}</span >
         </template>
      </b-pagination>
      
      <div class="text-center mt-4 mb-5">
         <p class="pages-message">Showing {{this.currentPageData.length}} of {{tableData.length}} items</p>
      </div>
   </div>
</template>

<script>
   import DisplayTableRow from './DisplayTableRow';
   import IconArrowRight from '../components/svgs/icons/IconArrowRight.vue';
   import IconArrowLeft from '../components/svgs/icons/IconArrowLeft.vue';
   import IconError from '../components/svgs/icons/IconError.vue';

   export default {
      name: 'DisplayTable',

      components:{
         DisplayTableRow,
         IconArrowRight,
         IconArrowLeft,
         IconError,
      },

      props: [
         'tableData',
         'addRowCallback',
         'deleteRowCallback',
         'editRowCallback',
         'filterText',
      ],

      data() {
         return{
            currentRowMenuOpened: null,
            addingNew: false,
            currentPageData: [],
            currentInputText: "",
            dayExpr: this.day_extr,
            pageNow: 0,
            currentPage: 1,
            rowsPerPage: 20,
            hasAddError: false,
            errorMessage: "",
         }
      },

      computed: {
         totalRows() {
            return this.filteredTableData.length;
         },
         filteredTableData() {
            return this.tableData.filter(
               (rowNow) => {
                  return (
                     rowNow.key.toLowerCase().includes(this.filterText.toLowerCase())
                     ||
                     rowNow.value.toLowerCase().includes(this.filterText.toLowerCase())
                  );
               }
            );
         },
      },

      watch: {
         currentPage: function () {
            this.closeCurrentRowMenu();
            this.currentPageData = this.getPagesData();
         },

         filterText: function () {
            this.closeCurrentRowMenu();
            this.currentPage = 1;
            this.currentPageData = this.getPagesData();
         },

         tableData: function () {
            this.closeCurrentRowMenu();
            this.currentPage = 1;
            this.currentPageData = this.getPagesData();
         },
      },

      methods: {
         getPagesData() {
            return this.filteredTableData.slice(((this.currentPage - 1) * this.rowsPerPage),( this.currentPage * this.rowsPerPage ));
         },

         constrictInput(e) {
            e.preventDefault();
            this.currentInputText = e.target.value.toLowerCase().replace(" ", "_");
         },

         onRowMenuClosed() {
            this.currentRowMenuOpened = null;
         },

         closeCurrentRowMenu() {
            if (this.currentRowMenuOpened !== null) {
               this.currentRowMenuOpened.closeMenu();
               this.currentRowMenuOpened = null;
            }
         },

         onRowDeleted(rowId) {
            this.closeCurrentRowMenu();
            this.deleteRowCallback(rowId);
         },

         onRowMenuOpened(newRow) {
            this.closeCurrentRowMenu();
            this.currentRowMenuOpened = newRow;
         },

         addNew(){
            if ( this.addingNew ) {
               return;
            }
            this.hasAddError = false;
            this.errorMessage = "";
            this.currentInputText = "";
            this.addingNew = true;
         },

         saveNew(){
            this.hasAddError = false;
            this.errorMessage = "";
            this.addRowCallback(
               {
                  id: "",
                  key: this.$refs.newRowKey.value,
                  value: this.$refs.newRowValue.value,
               }
            );
         },

         cancelNew(){
            this.addingNew = false;
         },

         onAdded(){
            this.cancelNew();
         },

         onAddedError(message) {
            this.hasAddError = true;
            this.errorMessage = message;
         },
      },
   }
</script>

<style scoped lang="scss">
@import '../styles/_main.scss';

.table-header {
   background-color: rgba(19, 20, 21,.05);
   font-size: $midFontSize !important;

   .header-text {
      font-family: $fontEuropaBold;
   }
}

.table-row {
   min-height: 64px;
   padding: 16px 0;
}

.table-row-new {
   border-bottom: 2px solid rgba(19, 20, 21,.05);
}

.page-markers {
   margin-top:20px;
}

.pages-message {
   font-family: $fontPlayfairRegular;
   font-size: $bigFontSize;
   color: $color9;
}

.arrow-icon {
   color: $color9;

   &.disabled {
      color: $color8;
   }
}

.error-message {
   padding-left: 10px;
   margin-bottom: 0;
}

.error-icon {
   display: inline-block;
   margin-right: 8px;
}
</style>