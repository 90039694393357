<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon / User</title>
      <g id="Icon-/-User" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(4.000000, 4.000000)" :fill="fillColor" id="icon/interfaces/user">
              <path d="M11.0778418,8.94069871 L14.5345074,10.5119103 C15.4269931,10.9175857 16,11.8074678 16,12.7878265 L16,13.5 C16,14.8807119 14.8807119,16 13.5,16 L2.5,16 C1.11928813,16 0,14.8807119 0,13.5 L0,12.7878265 C0,11.8074678 0.573006928,10.9175857 1.46549264,10.5119103 L4.92215823,8.94069871 C3.75209123,8.02553644 3,6.60059838 3,5 C3,2.23857625 5.23857625,0 8,0 C10.7614237,0 13,2.23857625 13,5 C13,6.60059838 12.2479088,8.02553644 11.0778418,8.94069871 L11.0778418,8.94069871 Z M5.96126583,9.5668358 L1.87929558,11.4222768 C1.34380416,11.665682 1,12.1996113 1,12.7878265 L1,13.5 C1,14.3284271 1.67157288,15 2.5,15 L13.5,15 C14.3284271,15 15,14.3284271 15,13.5 L15,12.7878265 C15,12.1996113 14.6561958,11.665682 14.1207044,11.4222768 L10.0387342,9.5668358 C9.41610538,9.84521347 8.72612885,10 8,10 C7.27387115,10 6.58389462,9.84521347 5.96126583,9.5668358 L5.96126583,9.5668358 Z M8,9 C10.209139,9 12,7.209139 12,5 C12,2.790861 10.209139,1 8,1 C5.790861,1 4,2.790861 4,5 C4,7.209139 5.790861,9 8,9 Z"></path>
          </g>
      </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
  },
  name: 'IconUser',
};
</script>
