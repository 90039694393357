<template>
   <div class="row-menu">
      <div v-if="!confirmDeleteMode" class="btn-group-vertical menu-button-group">
         <button class="btn btn-primary menu-button wide text-left top" @click.prevent.stop="editRow">EDIT</button>
         <button class="btn btn-primary menu-button wide text-left bottom" @click.prevent.stop="deleteRow">DELETE</button>
      </div>
      <div v-else class="btn-group-vertical menu-button-group">
         <div class="menu-title text-center"><span>DO YOU WANT TO DELETE?</span></div>
         <div class="btn-group menu-button">
            <button class="btn btn-primary menu-button left btn-confirm" @click.prevent.stop="confirmDeletion">CONFIRM</button>
            <button class="btn btn-primary menu-button right" @click.prevent.stop="cancelDeletion">CANCEL</button>
         </div>
      </div>
   </div>
</template>

<script>
   export default {
      name: 'RowMenu',

      props: [
      ],

      data() {
         return{
            confirmDeleteMode: false,
         }
      },

      methods: {
         editRow(){
            this.$emit('onEdit');
         },

         deleteRow(){
            this.confirmDeleteMode = true;
         },

         confirmDeletion(){
            this.confirmDeleteMode = false;
            this.$emit('onDeleteConfirmed');
         },

         cancelDeletion(){
            this.confirmDeleteMode = false;
         },
      },
   }
</script>

<style scoped lang="scss">
@import '../styles/_main.scss';

.row-menu {
   position: absolute;
   bottom: -116px;
   right: 14px;
   width: 278px;
   height: 104px;
   border-radius: 18px;
   z-index: 99;
}

.menu-title {
   color: $color6;
   background-color: $color1;
   height: 52px;
   border-radius: 18px 18px 0 0;
   border-bottom: 2px solid rgba(216,216,216,.2);
   font-size: $normalFontSize;
   padding-top: 13px;
}

.menu-button-group {
   display: block;
}

.menu-button {
   height: 52px;
   font-size: $normalFontSize;

   &.wide {
      width: 100%;
   }

   &.top {
      border-radius: 18px 18px 0 0;
      border-bottom: 2px solid rgba(216,216,216,.2);
   }

   &.bottom {
      border-radius: 0 0 18px 18px;
   }

   &.left {
      border-radius: 0 0 0 18px;
      border-right: 2px solid rgba(216,216,216,.2);
   }

   &.right {
      border-radius: 0 0 18px 0;
   }

   &.btn-confirm {
      &:focus,
      &:hover {
         background-color: $color18 !important;
         border-color: $color18 !important;
      }
   }
}

</style>