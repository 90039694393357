<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon / Mobile</title>
      <g id="Icon-/-Mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(6.000000, 3.000000)">
              <rect id="Rectangle" :stroke="fillColor" x="0.5" y="0.5" width="11" height="16" rx="2"></rect>
              <circle id="Oval" :fill="fillColor" cx="6" cy="14" r="1"></circle>
          </g>
      </g>
  </svg>  
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
  },
  name: 'IconMobile',
};
</script>
